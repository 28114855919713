<template>
  <div v-if="isAdmin || checkAccess('sales-by-sites')">
    <report-list-page
        page-title="Sales By Sites"
        page-icon="business"
        :descending="true"
        :headers="headers"
        state-end-point="salesCounter.salesCounters"
        :actions="actions"
        :show-component="showComponent"
        item-key="detailId"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "SalesBySites",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Invoice Date',
          value: 'createdAt'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Site',
          value: 'site',
          sortable: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'paymentStatus'
        },
        {
          text: 'G. Admin Price',
          align: 'right',
          value: 'adminPrice'
        },
        {
          text: 'G. Other Price',
          align: 'right',
          value: 'adminOthersPrice'
        },
        {
          text: 'Publishable Amount',
          align: 'right',
          value: 'publishableAmount'
        },
        {
          text: 'Writing Price',
          align: 'right',
          value: 'articleWritingPrice'
        },
        {
          text: 'Sub Total',
          align: 'right',
          value: 'total'
        },
        {
          text: 'Discount',
          align: 'right',
          value: 'discount'
        },
        {
          text: 'Total',
          align: 'right',
          value: 'excludeDiscountTotal'
        }
      ],
      actions: {
        load: 'salesCounter/loadSitesReports',
      },
      showComponent: {
        isUser: true,
        isStatus: true,
        isMethod: false,
        isEmail: true,
        isSite: true,
        isDate: true,
        isAdminPrice: true,
        isSalesListPDF: true,
      }
    }
  }
}
</script>

<style scoped>

</style>